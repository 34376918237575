import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Contact from "../components/Pages/Contact"

import "../styles/styles.css"

const query = graphql`
  query {
    image: imageSharp(
      original: { src: { regex: "/.*dotted-kangaaroo-light.*/" } }
    ) {
      id
      fixed(height: 350, quality: 92) {
        ...GatsbyImageSharpFixed
      }
    }
    page: markdownRemark(fields: { slug: { eq: "/pages/contacts/" } }) {
      frontmatter {
        title
        text
      }
    }
  }
`
const ContactPage = props => (
  <StaticQuery
    query={query}
    render={data => {
      return <Contact image={data.image} page={data.page.frontmatter} {...props} />
    }}
  />
)

export default ContactPage
